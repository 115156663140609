import React, { useState, useEffect } from 'react'
import { connect } from "react-redux";
import { useHistory } from 'react-router-dom';
//components
import Index from "../../common/Components/Index";
import notifications from '../../common/Components/Notifications/Notification';
import { QuickFilter } from './QuickFilter';
//actions
import {
    getCatAlogue,
    createCatAlogue,
    updateCatAlogue,
    deleteCatAlogue,
} from "../../Store/catelogue/actions";
//files
import { debounce } from "../../common/commonFiles/debounce";
import { HeaderData } from './Header';
import { isArray } from 'lodash';
import DeleteConfirm from '../../common/Components/DeleteConfirm';
import CatalogueModal from './CatalogueModal';
// variables
var offset: any = 0;
var limit: any = 10;
var totPages = 0;
var totCnt: any = 0;
var toVal: any = 0;
var fromVal: any = 0;
const Catalog = (props: any) => {
    const history = useHistory();
    const [loading, setLoading] = useState(false);
    const [catalogData, setCatalogData] = useState([])
    const [openDeleteModal, setOpenDeleteModal] = useState(false);
    const [deleteCataloge, setDeleteCatalogData] = useState(null)
    const [editCatalogueData, setEditCatalogueData] = useState(null);
    const [openCreateCatalogueModal, setOpenCreateCatalgueModal] = useState(false)
    useEffect(() => {
        getAllData();
    }, [])
    const handlePageChange = (event: any, value: any) => {
        offset = (value - 1) * 1;
        localStorage.setItem(
            "offsetForCatalogue", offset
          );
        getAllData();
    };
    const getAllData = () => {
        setLoading(true)
        let offsetForCatalgue: any = localStorage.getItem("offsetForCatalogue");
        let payload = {
            offset: offsetForCatalgue || offset,
            limit: limit
        }
        props.getCatAlogue(payload, (res: any) => {
            let data: any = [];
            console.log(res)
            setLoading(false)

            if (isArray(res?.data?.docs)) {
                totCnt = res?.data?.length;
                totPages = Math.ceil(totCnt / 10);
                fromVal = totCnt !== 0 ? offset * 10 + 1 : fromVal;
                toVal =
                    res?.data?.offset * 10 +
                    res?.data?.docs?.length;
                res?.data?.docs.forEach((ele: any, i: any) => {
                    data.push({
                        name: (
                            <span
                              onClick={() => {
                                history.push(`/pre-view-catalogue-products/${ele?._id}`, {
                                  productDetails: ele,
                                });
                              }}
                            >
                              {" "}
                              {ele?.name}
                            </span>
                          ),
                        description: ele?.description,
                        actions: (
                            <div className="d-flex align-items-center tableBtns">
                                <span
                                    className="btns"
                                    onClick={() => {
                                        history.push(`/pre-view-catalogue-products/${ele?._id}`, {
                                            catalogueName: ele?.name
                                        });
                                    }}
                                >
                                    Pre-View E-commerce
                                </span>
                                <span className="actionLineHeight me-2"></span>

                                <span
                                    className="btns"
                                    onClick={() => {
                                        history.push(`/view-catalogue-products/${ele?._id}`, {
                                            catalogueName: ele?.name
                                        });
                                    }}
                                >
                                    View Catalog Product
                                </span>
                                <span className="actionLineHeight me-2"></span>
                                <span
                                    className="btns"
                                    onClick={() => {
                                        openCreateOrEditCatalogueModal();
                                        setEditCatalogueData(ele)
                                    }}

                                >
                                    Edit
                                </span>
                                <span className="actionLineHeight"></span>
                                &nbsp;&nbsp;

                                <span
                                    className="btns"
                                    onClick={() => {
                                        setOpenDeleteModal(true);
                                        setDeleteCatalogData(ele)

                                    }}
                                >
                                    Delete
                                </span>
                            </div>
                        ),

                    })

                })
                setCatalogData(data)
            }
        })
    }
    let deleteDataWithConfirm = (deleteData: any) => {
        props.deleteCatAlogue(
            deleteData?._id,
            (res: any) => {
                if (res.success) {
                    notifications({
                        title: "Success!",
                        message: res?.message,
                        type: "success",
                    });
                    getAllData();
                } else {
                    notifications({
                        title: "Error!",
                        message: res?.message,
                        type: "danger",
                    });
                }
            }
        );
    };
    const warningToggle = () => {
        setOpenDeleteModal(false)
    };
    const openCreateOrEditCatalogueModal = () => {
        setOpenCreateCatalgueModal(!openCreateCatalogueModal)
    }
    return (
        <>
            <CatalogueModal
                {...props}
                openModal={openCreateCatalogueModal}
                closeModal={openCreateOrEditCatalogueModal}
                editData={editCatalogueData}
                getAllData={getAllData}
            />
            <DeleteConfirm
                {...props}
                warningModal={openDeleteModal}
                warningToggle={warningToggle}
                deleteDataWithConfirm={deleteDataWithConfirm}
                deleteData={deleteCataloge}
                getAllData={getAllData}
                name={"Catalog Name"}
            />
            <QuickFilter
                {...props}
                openModalPopUp={openCreateOrEditCatalogueModal}
                // handleResetData={handleResetData}
                getAllData={getAllData}
            // filters={filters}
            // handleOnchangeFilter={handleOnchangeFilter}
            // handleKeyDown={handleKeyDown}
            />
            <Index
                {...props}
                data={catalogData}
                headerData={HeaderData}
                limit={limit}
                handleChange={handlePageChange}
                offset={offset}
                totPages={totPages}
                loading={loading}
                fromVal={fromVal}
                toVal={toVal}
                totCnt={totCnt}
            />
        </>
    )
}

export default connect(null, {
    getCatAlogue,
    createCatAlogue,
    updateCatAlogue,
    deleteCatAlogue,
})(Catalog);