import React from 'react'
import { Grid, Card, Slider } from "@mui/material";
import Pagination from "@mui/material/Pagination";
import { SideNavEcomerce } from './SideNavEcomerce';
import { useHistory } from 'react-router-dom';
const EComerceProduct = (props: any) => {
  let { productData, offset, totPages, handleChange } = props;
  console.log("productInfo", productData)
  const history = useHistory();
  return (
    <>
      <Grid container spacing={2}>
        {/* <Grid item xs={0} md={3} >
           <SideNavEcomerce />
        </Grid> */}
        <Grid item xs={12} md={12}>
          {
            productData?.length > 0 ?
              <>
                <Grid
                  container
                  spacing={2}
                  className='catalogue-product-card1'
                >
                  {
                    productData?.map((ele: any, i: any) => {
                      return (
                        <Grid item xs={12} sm={6} md={4} lg={3}>
                          <Card className='catalogue-product cursor-pointer'
                            onClick={() => {
                              history.push(`/product-info/${ele?.id}`)

                            }}
                          >
                            <div className='image-div'>{ele?.logo}</div>
                            <div className='product-title'>{ele?.productName}</div>
                            <div className='product-price'> {ele?.sellPrice ? "₹ " + ele?.sellPrice : <button>Request for price</button>}</div>
                          </Card>
                        </Grid>
                      )
                    })
                  }

                </Grid>
                <Pagination
                  size="small"
                  shape="rounded"
                  count={totPages}
                  color="primary"
                  page={(offset * 10) / 10 + 1}
                  onChange={handleChange}
                  defaultPage={1}
                  siblingCount={1}
                  className='catalogue-page'
                />
              </> : <div className='no-product'>
                No products are in catalogue
              </div>
          }
        </Grid>
      </Grid>


    </>
  )
}

export default EComerceProduct