import React from 'react'
import { Card } from 'reactstrap'
export default function HeaderEcomerce() {
  return (
    <Card className='header-ecomerce'>
      <div className='Ecomerce-div'>
        <div>Test Organization</div>
        <div className="MainHeading">
          <span className="sp1">Hastkala</span>{" "}
          <span className="sp2">Paramanak</span>
          <p>Supporting the makers, Protecting the buyers</p>
        </div>
      </div>
    </Card>
  )
}
